export default {
    name: 'Avatar',
    props: {
        img: String,
        label: String,
        bagde: Object,
        link: String,
        size: String,
        overlap: Boolean,
        rank: Number,
        member: Boolean
    },
}