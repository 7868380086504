import {
    ADD,
    db,
    GET,
    UPDATE,
    DELETE,
    firebase
} from "@/firebase.js";

import Badge from "@/components/Badge/Badge.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Avatar from "@/components/Avatar/Avatar.vue";

export default {
    name: "AC",
    components: {
        Badge,
        Tabs,
        Avatar
    },
    data: function () {
        return {
            org: {
                name: null,
                oid: null,
                logo: null,
                org_nums: [],
                categories: [],
                thesis_link: null,
                member: false,
                id: null,
                KPI: {},
            },
            tabs: ['Profile', 'KPI', 'Badges'],
            activeTab: 'Profile',
            orgNum: null,
            categories: null,
            prefill: false,
            badges: [],
            lastVisibleBadge: null,
            promises: [],
            deletedPromises: [],
            newPromises: [],
            badgesQueryBusy: false,
            allBadgesFetched: false,
            activeBadge: null,
            showSaved: false
        };
    },
    methods: {
        activateBadge: function (badge) {
            this.activeBadge = badge
        },
        changeTab: function (tab) {
            this.activeTab = tab
        },
        submitForm: function () {
            if (this.prefill) this.updateOrg();
            else this.addOrg();
        },
        addOrg: function () {
            let col = db.collection("orgs");
            ADD(col, this.org).then(async (doc) => {
                this.org.id = doc.id;
                await this.deletePromisesInDB();
                await this.addPromisesToDB();
                this.showSaved = true
            });
        },
        updateOrg: function () {
            this.normalizeKPI()
            let doc = db.doc("orgs/" + this.org.id);
            UPDATE(doc, this.org).then(async () => {
                await this.deletePromisesInDB();
                await this.addPromisesToDB();
                this.showSaved = true
            });
        },
        addOrgNum: function () {
            let sanitizedNum = this.orgNum.replace(/-/g, '')
            this.org.org_nums.push(sanitizedNum);
            this.orgNum = null;
        },
        removeOrgNum: function (index) {
            this.org.org_nums.splice(index, 1);
        },
        addCategory: function () {
            this.org.categories.push(this.categories);
            this.categories = null;
        },
        removeCategory: function (index) {
            this.org.categories.splice(index, 1);
        },
        prefillData: function () {
            this.prefill = true;
            console.log("Prefill for ", this.$route.params.id);
            let col = db.collection("orgs");
            col = col.where('oid', '==', this.$route.params.id)
            GET(col, true).then((orgs) => {
                console.log(orgs)
                let org = orgs.docs[0]
                this.org = org.data();
                this.org.id = org.id;
                if (!this.org.org_nums) this.org.org_nums = [];
                if (!this.org.categories) this.org.categories = [];
                this.prefillKPIGaps()
                console.log(this.org.KPI)
                console.log(this.org)
                this.getCompanyPromises();
            });
        },
        kpiNeedsNormalizing: function (kpi) {
            return (kpi.split('_')[1] === 'eq')
        },
        normalizeKPI: function () {
            for (let k of Object.keys(this.org.KPI)) {
                let yearCount = 0
                let years = Object.keys(this.org.KPI[k])
                let firstYear = null
                for (let year of years) {
                    if (year === 'trend') continue
                    if (yearCount === 0) {
                        firstYear = year
                    }
                    if (yearCount === (years.length - 1)) {
                        this.org.KPI[k].trend = this.org.KPI[k][year].raw - this.org.KPI[k][firstYear].raw
                    }
                    yearCount++
                    console.log(this.org.KPI[k][year].raw)
                    this.org.KPI[k][year].raw = parseFloat(this.org.KPI[k][year].raw)
                    if (this.kpiNeedsNormalizing(k)) {
                        if (this.org.KPI[k][year].raw > 50) {
                            this.org.KPI[k][year].normalized = 100 - this.org.KPI[k][year].raw
                        } else {
                            this.org.KPI[k][year].normalized = this.org.KPI[k][year].raw
                        }
                    }
                }
            }
        },
        prefillKPIGaps: function () {
            let years = ['2020', '2017']
            let KPI = ['senmang_eq', 'boa_eq', 'tco2e-per-hc_ghg', 'total_eq']
            KPI.forEach((k) => {
                years.forEach((year) => {
                    if (!this.org.KPI[k]) {
                        this.org.KPI[k] = {}
                    }
                    if (!this.org.KPI[k][year]) {
                        this.org.KPI[k][year] = {}
                    }
                    if (!this.org.KPI[k][year].raw) {
                        this.org.KPI[k][year].raw = null
                    }
                    if (typeof this.org.KPI[k][year].raw === 'string') {
                        this.org.KPI[k][year].raw = parseFloat(this.org.KPI[k][year].raw)
                    }
                    if (!this.org.KPI[k][year].normalized && this.kpiNeedsNormalizing(k)) {
                        this.org.KPI[k][year].normalized = null
                    }
                })
            })
            console.log(this.org.KPI)
        },
        togglePromise: function (badge) {
            console.log(badge)
            let promise = this.promises.find((promise) => promise.badge === badge.id);
            if (promise) this.deletePromise(promise);
            else this.addPromise(badge);
        },
        getBadges: function () {
            if (this.badgesQueryBusy || this.allBadgesFetched) return console.log('Cancel query')
            console.log('Start badge fetch')
            this.badgesQueryBusy = true
            let col = db.collection("badges");
            col = col.limit(6);
            col = col.orderBy('title', 'asc')
            if (this.lastVisibleBadge) col = col.startAfter(this.lastVisibleBadge);
            GET(col, true).then((badges) => {
                this.lastVisibleBadge = badges.docs[badges.docs.length - 1];
                if (!this.lastVisibleBadge) {
                    this.allBadgesFetched = true
                    return
                }
                badges.forEach((badge) => {
                    let badgeObj = {
                        title: badge.data().title,
                        type: badge.data().type,
                        icon: badge.data().icon,
                        id: badge.id,
                        desc: badge.data().desc
                    };
                    this.badges.push(badgeObj);
                });
                this.badgesQueryBusy = false
            });
        },
        getCompanyPromises: function () {
            let col = db.collection("promises");
            col = col.where("org", "==", this.org.id);
            GET(col).then((promises) => {
                promises.forEach(async (promise) => {
                    let badge = await this.getBadgeFromDB(promise.data().badge)
                    badge.id = promise.data().badge.id
                    let promiseObj = {
                        badge: promise.data().badge.id,
                        id: promise.id,
                        badgeData: badge,
                    };
                    if (promise.data().content)
                        promiseObj.content = promise.data().content.path;
                    this.promises.push(promiseObj);
                });
            });
        },
        getBadgeFromDB: function (badge) {
            return new Promise((resolve) => {
                let doc = db.doc('badges/' + badge.id)
                GET(doc).then((badgeObj) => {
                    resolve(badgeObj.data())
                })
            })
        },
        addPromisesToDB: function () {
            console.log('Adding promise')
            let promiseAddCount = 0;
            return new Promise((resolve) => {
                if (this.newPromises.length === 0) {
                    resolve(false);
                }
                let col = db.collection("promises");
                this.newPromises.forEach((promise) => {
                    ADD(col, {
                        badge: db.collection("badges").doc(promise.badge),
                        org: this.org.id,
                        created: new Date(),
                    }).then(() => {
                        promiseAddCount++;
                        if (promiseAddCount === this.newPromises.length) {
                            resolve(true);
                        }
                    });
                });
            });
        },
        deletePromisesInDB: function () {
            console.log('Deleting promise')
            let promiseDeleteCount = 0;
            return new Promise((resolve) => {
                if (this.deletedPromises.length === 0) {
                    resolve(false);
                }
                this.deletedPromises.forEach((promise) => {
                    console.log(promiseDeleteCount);
                    let doc = db.collection("promises").doc(promise.id);
                    DELETE(doc).then(() => {
                        this.promises = this.promises.filter(
                            (promiseChild) => promiseChild.id !== promise.id
                        );
                        let orgDoc = db.doc("orgs/" + this.org.id);
                        UPDATE(orgDoc, {
                            badges: firebase.firestore.FieldValue.arrayRemove(
                                db.collection("badges").doc(promise.badge)
                            ),
                        }).then(() => {
                            promiseDeleteCount++;
                            if (promiseDeleteCount === this.deletedPromises.length) {
                                resolve(true);
                            }
                        });
                    });
                });
            });
        },
        addPromise: function (badge) {
            console.log('Adding promise', badge)
            this.promises.push({
                badge: badge.id,
                badgeData: badge
            });
            this.newPromises.push({
                badge: badge.id,
            });
            this.deletedPromises = this.deletedPromises.filter(
                (promiseItem) => promiseItem.badge !== badge.id
            );
        },
        deletePromise: function (promise) {
            console.log(promise);
            if (promise.id) {
                this.deletedPromises.push({
                    badge: promise.badge,
                    id: promise.id,
                });
            }
            this.promises = this.promises.filter(
                (promiseItem) => promiseItem.badge !== promise.badge
            );
            this.newPromises = this.newPromises.filter(
                (promiseItem) => promiseItem.badge !== promise.badge
            );
        },
    },
    mounted: function () {
        if (this.$route.params.id) this.prefillData();
        this.getBadges();
    },
};